<template>
  <div>
    <div class="hero is-primary">
      <div class="hero-body">
        <div class="container">
          <h1 class="title is-size-1">{{ title }}</h1>
        </div>
      </div>
    </div>
    <div class="container">
      <v-card :loading="loading" class="mb-4 pa-6">
        <v-row>
          <v-col cols="6">
            <v-select
              menu-props="auto"
              v-model="selectedCarrier"
              :items="carriersWithServices"
              item-text="name"
              return-object
              label="Select Carrier"
              outlined
              dense
              @change="setCarrierServices"
            ></v-select>
          </v-col>
          <v-col cols="6">
            <v-select
              v-if="selectedCarrier && selectedCarrier.id && carrierServices"
              menu-props="auto"
              v-model="selectedCarrierService"
              :items="carrierServices"
              item-text="service"
              return-object
              label="Select Carrier Service"
              outlined
              dense
              @change="getCarrierBillingSurcharges"
            ></v-select>
          </v-col>
        </v-row>
        <v-row
          v-if="
            selectedCarrier &&
            selectedCarrier.id &&
            selectedCarrierService &&
            selectedCarrierService.id
          "
        >
          <!-- current surcharges -->
          <v-col cols="12">
            <v-card outlined class="pa-2">
              <v-card-title
                >Current Surcharges for
                {{ selectedCarrierService.service }}</v-card-title
              >
              <v-data-table
                :headers="currentCarrierBillingSurchargesHeaders"
                :items="currentCarrierBillingSurcharges"
                :items-per-page="5"
                v-if="currentCarrierBillingSurcharges.length"
              > 
              <template v-slot:item.flat_charge_per_order="{ item }">
                  <v-text-field
                    v-if="
                      updating &&
                      updateForm.id === item.id &&
                      item.flat_charge_per_order
                    "
                    type="number"
                    v-model="item.flat_charge_per_order"
                    label="Flat Charge per Order"
                    dense
                    outlined
                    step="0.01"
                    prefix="£"
                  ></v-text-field>
                  <div v-else>
                   {{item.flat_charge_per_order }}
                  </div>
                </template>
                
                <template v-slot:item.percentage_charge_per_order="{ item }">
                  <v-text-field
                    v-if="
                      updating &&
                      updateForm.id === item.id &&
                      item.percentage_charge_per_order
                    "
                    type="number"
                    v-model="item.percentage_charge_per_order"
                    label="Percentage Charge per Order"
                    dense
                    outlined
                    step="0.01"
                    suffix="%"
                  ></v-text-field>
                  <div v-else>
                    {{ convertToPercentage(item.percentage_charge_per_order, true) }}
                  </div>
                </template>
                <template v-slot:item.flat_charge_per_package="{ item }">
                  <v-text-field
                    v-if="
                      updating &&
                      updateForm.id === item.id &&
                      item.flat_charge_per_package
                    "
                    type="number"
                    v-model="item.flat_charge_per_package"
                    label="Flat Charge per Package"
                    dense
                    outlined
                    step="0.01"
                    prefix="£"
                  ></v-text-field>
                  <div v-else>
                   {{item.flat_charge_per_package }}
                  </div>
                </template>
                <template v-slot:item.percentage_charge_per_package="{ item }">
                  <v-text-field
                    v-if="
                      updating &&
                      updateForm.id === item.id &&
                      item.percentage_charge_per_package
                    "
                    type="number"
                    v-model="item.percentage_charge_per_package"
                    label="Percentage Charge per Package"
                    dense
                    outlined
                    step="0.01"
                    suffix="%"
                  ></v-text-field>
                  <div v-else>
                    {{
                      convertToPercentage(item.percentage_charge_per_package, true)
                    }}
                  </div>
                </template>
                <template v-slot:item.action="{ item }">
                  <v-btn
                    v-if="updating && updateForm.id === item.id"
                    small
                    @click="resetUpdatingSurcharge(item)"
                    color="red"
                    class="mb-1"
                    outlined
                  >
                    <b>X</b>
                  </v-btn>
                  <v-btn
                    v-if="updating && updateForm.id === item.id"
                    small
                    @click="updateBillingSurcharge(item)"
                    color="primary"
                    outlined
                  >
                    <v-icon> check </v-icon>
                  </v-btn>
                  <v-btn
                    v-if="!updating"
                    small
                    @click="toggleUpdateBillingSurcharge(item)"
                    color="primary"
                    class="mb-1"
                    outlined
                  >
                    <v-icon> edit </v-icon>
                  </v-btn>
                  <v-btn
                    v-if="!updating"
                    small
                    @click="deleteCarrierBillingSurcharge(item)"
                    color="red"
                    outlined
                  >
                    <v-icon> delete </v-icon>
                  </v-btn>
                </template>
              </v-data-table>
              <div v-else class="ma-4">This service has no surcharges yet</div>
            </v-card>
          </v-col>
          <!-- insert new surcharge -->
          <v-col cols="12">
            <v-card outlined class="pa-2" :loading="loading">
              <v-card-title
                >Insert Carrier Billing Surcharge For
                {{ selectedCarrierService.service }}</v-card-title
              >
              <v-row class="ma-2">
                <v-col cols="4">
                  <v-checkbox
                    class="mt-0"
                    v-model="surchargeByZone"
                    label="By Zone"
                  >
                  </v-checkbox>
                </v-col>

                <v-col cols="4">
                  <v-select
                    menu-props="auto"
                    v-model="selectedCarrierBillingZone"
                    :items="carrierBillingZones"
                    item-text="reference"
                    return-object
                    label="Select Carrier Zone"
                    outlined
                    dense
                    v-if="surchargeByZone"
                  ></v-select>
                </v-col>
                <v-col cols="4">
                  <v-select
                    menu-props="auto"
                    v-model="uom"
                    :items="['per package', 'per order']"
                    label="Select UOM"
                    outlined
                    dense
                  ></v-select>
                </v-col>

                <v-col cols="4">
                  <v-text-field
                    v-model="description"
                    label="Surcharge Description"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="4" v-if="!percentageCharge">
                  <v-text-field
                    type="number"
                    v-model="flatCharge"
                    label="Flat Charge"
                    dense
                    outlined
                    step="0.01"
                    prefix="£"
                  ></v-text-field>
                </v-col>
                <v-col cols="4" v-if="!flatCharge">
                  <v-text-field
                    type="number"
                    v-model="percentageCharge"
                    label="Percentage Charge"
                    dense
                    outlined
                    step="0.01"
                    suffix="%"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" align="center">
                  <v-btn
                    :disabled="
                      !selectedCarrierService ||
                      !selectedCarrierService.id ||
                      !description ||
                      !uom ||
                      (!flatCharge && !percentageCharge)
                    "
                    color="primary"
                    outlined
                    @click="insertCarrierBillingSurcharge"
                    >Save Surcharge
                  </v-btn>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </div>
    <v-snackbar v-model="snackbar" :timeout="3000">
      {{ text }}
      <v-btn color="blue" text @click="snackbar = false"></v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import Carriers from "@/services/Carriers.js";
export default {
  data() {
    return {
      validForm: true,
      // Title
      title: "Carrier Billing Surcharges",
      // Snackbar
      snackbar: false,
      text: "",
      loading: false,

      carriersWithServices: [],
      selectedCarrier: null,
      carrierServices: null,
      selectedCarrierService: null,

      carrierBillingZones: null,
      selectedCarrierBillingZone: null,
      surchargeByZone: false,

      currentCarrierBillingSurcharges: [],
      currentCarrierBillingSurchargesHeaders: [
        { text: "id", value: "id" },
        { text: "Carrier Service", value: "carriersService.service" },
        { text: "Carrier Billing Zone", value: "carrierBillingZone.reference" },
        { text: "Description", value: "description" },
        { text: "Flat Charge Per Order", value: "flat_charge_per_order" },
        {
          text: "Percentage Charge Per Order",
          value: "percentage_charge_per_order",
        },
        { text: "Flat Charge Per Package", value: "flat_charge_per_package" },
        {
          text: "Percentage Charge Per Package",
          value: "percentage_charge_per_package",
        },
        { text: "", value: "action" },
      ],

      flatCharge: null,
      percentageCharge: null,
      description: "",
      uom: null,

      // updating form
      updating: false,
      updateForm: null,
    };
  },

  created() {
    this.resetUpdatingSurcharge();
    this.getCarriersWithServices();
    this.getCarrierBillingZones();
  },
  methods: {
    resetUpdatingSurcharge(item) {
      this.updating = false;
      if(item){
        item.flat_charge_per_order = this.updateForm.flat_charge_per_order
        item.percentage_charge_per_order = this.updateForm.percentage_charge_per_order
        item.flat_charge_per_package = this.updateForm.flat_charge_per_package
        item.percentage_charge_per_package = this.updateForm.percentage_charge_per_package

        
      }
      this.updateForm = {
        id: null,
        flat_charge_per_order: null,
        percentage_charge_per_order: null,
        flat_charge_per_package: null,
        percentage_charge_per_package: null,
      };
   
    },
    toggleUpdateBillingSurcharge(item) {
      this.updating = true;
      this.updateForm = { ...item };
      item.percentage_charge_per_order = this.convertToPercentage(item.percentage_charge_per_order)  
      item.percentage_charge_per_package = this.convertToPercentage(item.percentage_charge_per_package)
    },
    updateBillingSurcharge(item) {

      if(item.percentage_charge_per_order){
        item.percentage_charge_per_order =  this.convertPercentageToDecimal(item.percentage_charge_per_order)
      }
      if(item.percentage_charge_per_package){
        item.percentage_charge_per_package =  this.convertPercentageToDecimal(item.percentage_charge_per_package)
      }
      


      this.loading = true;

      Carriers.updateCarrierBillingSurcharge(item)
        .then((response) => {
          this.resetUpdatingSurcharge()
          this.loading = false;
          this.snackbar = true;
          this.text = `${response.message}`;
         
        })
        .catch((error) => {
          this.snackbar = true;
          this.text = error.response
            ? error.response.data.message
            : `${error.message}`;
          this.loading = false;
        });
    },
    setCarrierServices(carrier) {
      this.carrierServices = carrier.carrierServices;
    },
    convertToPercentage(number, attachSymbol = false) {
      let newVal = number ? Math.round(number * 10000) / 100 : ""
      return attachSymbol && newVal ? newVal + '%' : newVal;
    },
    getCarriersWithServices() {
      this.loading = true;
      Carriers.getCarriersWithServices()
        .then((response) => {
          this.loading = false;
          this.snackbar = true;
          this.text = `${response.message}`;
          this.carriersWithServices = response.data;
        })
        .catch((error) => {
          this.snackbar = true;
          this.text = error.response
            ? error.response.data.message
            : `${error.message}`;
          this.loading = false;
        });
    },
    getCarrierBillingSurcharges() {
      this.loading = true;
      this.currentCarrierBillingSurcharges = [];
       this.resetUpdatingSurcharge()
      Carriers.getCarrierBillingSurcharges(this.selectedCarrierService.id)
        .then((response) => {
          this.loading = false;
          this.currentCarrierBillingSurcharges = response.data;
        })
        .catch((error) => {
          this.snackbar = true;
          this.text = error.response
            ? error.response.data.message
            : `${error.message}`;
          this.loading = false;
        });
    },
    deleteCarrierBillingSurcharge(item) {
      let confirmMsg = `Are you sure you want to delete this ${item.carriersService.service} - ${item.description} surcharge?`;
      if (confirm(confirmMsg)) {
        this.loading = true;
        let reqBody = {
          id: item.id,
        };
        Carriers.deleteCarrierBillingSurcharge(reqBody)
          .then((response) => {
            this.loading = false;
            this.snackbar = true;
            this.text = `${response.message}`;

            this.getCarrierBillingSurcharges();
          })
          .catch((error) => {
            this.snackbar = true;
            this.text = error.response
              ? error.response.data.message
              : `${error.message}`;
            this.loading = false;
          });
      }
    },
    convertPercentageToDecimal(value){
      return value ? value / 100 : ""
    },
    insertCarrierBillingSurcharge() {
      let carrierServiceId = this.selectedCarrierService
        ? this.selectedCarrierService.id
        : null;
      let description = this.description;
      let flatCharge = this.flatCharge;
      let percentageCharge = this.percentageCharge;
      let uom = this.uom;
      if (
        !carrierServiceId ||
        !description ||
        !uom ||
        (!flatCharge && !percentageCharge)
      )
        return false;

      let reqBody = {
        carrier_service_id: carrierServiceId,
        carrier_billing_zone_id:
          this.surchargeByZone && this.selectedCarrierBillingZone
            ? this.selectedCarrierBillingZone.id
            : null,
        description: description,
        uom: uom,
        flat_charge: flatCharge ? flatCharge : null,
        percentage_charge: percentageCharge ? percentageCharge / 100 : null,
      };
      this.loading = true;

      Carriers.insertCarrierBillingSurcharge(reqBody)
        .then((response) => {
          this.loading = false;
          this.snackbar = true;
          this.text = `${response.message}`;
          this.clearFields();
          this.getCarrierBillingSurcharges();
        })
        .catch((error) => {
          this.snackbar = true;
          this.text = error.response
            ? error.response.data.message
            : `${error.message}`;
          this.loading = false;
        });
    },
    clearFields() {
      this.surchargeByZone = false;
      this.flatCharge = null;
      this.percentageCharge = null;
      this.uom = null;
      this.description = "";
    },
    getCarrierBillingZones() {
      this.loading = true;
      Carriers.getCarrierBillingZones()
        .then((response) => {
          this.loading = false;
          this.snackbar = true;
          this.text = `${response.message}`;
          this.carrierBillingZones = response.data;
        })
        .catch((error) => {
          this.snackbar = true;
          this.text = error.response
            ? error.response.data.message
            : `${error.message}`;
          this.loading = false;
        });
    },
  },
};
</script>
<style></style>
